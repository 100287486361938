<mat-drawer-container>
  <mat-drawer *ngIf="opened" class="mapping-add" mode="over" position="end" [(opened)]="opened">
    <app-mapping-add [drawerOpen]="opened" (closed)="closeCreate()" [mapping]="newMapping" [mode]="mode"></app-mapping-add>
  </mat-drawer>
  <mat-toolbar>
    <span class="space-fill"></span>
    <button mat-raised-button color="accent" (click)="createMap()">{{'MAP.MAP_ADD' | translate}}</button>
  </mat-toolbar>
  <app-errormessage [error]="error"></app-errormessage>
  <div *ngIf="loading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="container" [ngClass]="{'has-error': error.message}">
    <div class="header">
      <mat-form-field class='filter' appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Search title and description" #input>
        <button class="cancel" [disabled]="input.value == ''" (click)="clearInput(input)"><mat-icon>cancel</mat-icon></button>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Roles</mat-label>
        <mat-select [(value)]="roles.defaultValue" (selectionChange)="applyRoleFilter($event)">
          <mat-option *ngFor="let op of roles.options" [value]="op">
            {{op |titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-paginator #paginator
                     [length]="totalElements"
                     [pageIndex]="currentPage"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="pageSizeOptions"
                     (page)="pageChanged($event)"
                     [showFirstLastButtons]="true">
      </mat-paginator>
    </div>
    <div class="container-scroll-list" [ngClass]="{'has-error': error.message}">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title">{{'PROJECT_TABLE.TITLE' | translate}}</th>
          <td mat-cell *matCellDef="let project">{{project.title}}</td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>{{'PROJECT_TABLE.DESCRIPTION' | translate}}</th>
          <td mat-cell *matCellDef="let project">{{project.description}}</td>
        </ng-container>
        <ng-container matColumnDef="modified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by last updated">{{'PROJECT_TABLE.LAST_UPDATED' | translate}}</th>
          <td mat-cell *matCellDef="let project">{{selectedMapping[project.id]?.modified | date }}</td>
        </ng-container>
        <ng-container matColumnDef="users">
          <th mat-header-cell *matHeaderCellDef>{{'PROJECT_TABLE.USERS' | translate}}</th>
          <td mat-cell *matCellDef="let project"><app-project-badges [project]="project"></app-project-badges></td>
        </ng-container>
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef>{{'PROJECT_TABLE.VERSION' | translate}}</th>
          <td mat-cell *matCellDef="let project" (click)="$event.stopPropagation()">
            <mat-select [(value)]="selectedMapping[project.id]">
              <mat-option *ngFor="let m of project.maps" [value]="m">
                {{m.mapVersion}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>{{'PROJECT_TABLE.ACTIONS' | translate}}</th>
          <td mat-cell *matCellDef="let project" (click)="$event.stopPropagation()">
            <button mat-stroked-button color="primary" title="{{'MAP.NEW_VERSION' | translate}}"
                    [disabled]="!isOwner(project)" (click)="newVersion(project)">{{'MAP.NEW_VERSION' | translate}}</button>
            <button mat-stroked-button mat-icon-button color="primary" title="{{'PROJECT.DELETE_TOOLTIP' | translate}}"
                    [disabled]="!hasProjectRole(project)" (click)="deleteProject(project)"><mat-icon>delete</mat-icon></button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row (click)="viewMapping(row)" *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div *ngIf="projects.length === 0">
        <mat-card>
          <mat-card-header>
            <mat-card-title>
              <mat-icon>info</mat-icon>
              {{'MAP.NO_MAPS_FOUND' | translate}}</mat-card-title>
          </mat-card-header>
        </mat-card>
      </div>
    </div>
  </div>
</mat-drawer-container>
