/*
 * Copyright © 2022 SNOMED International
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EffectsModule} from '@ngrx/effects';
import {AuthEffects} from './auth-feature/auth.effects';
import {MappingEffects} from './mapping-feature/mapping.effects';
import {SourceEffects} from './source-feature/source.effects';
import {FhirEffects} from './fhir-feature/fhir.effects';
import {MetaReducer} from '@ngrx/store';
import {flushStateReducer, localStorageSyncReducer} from './app.reducers';

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer, flushStateReducer];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([AuthEffects, MappingEffects, SourceEffects, FhirEffects])
  ]
})
export class StoreModule {
}
