/*
 * Copyright © 2022 SNOMED International
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'trim'})
export class TrimPipe implements PipeTransform {
  transform(namefield: string | undefined): string | undefined {
    const maxwidth = (window.innerWidth <= 1000) ? 20 : (window.innerWidth <= 2000) ? 40 : 60;
    if (namefield && namefield.length > maxwidth) {
      return namefield.substr(0, maxwidth) + '...';
    }
    return namefield;
  }
}
