<mat-card class="properties-control">
  <mat-card-header>
    <mat-card-title>
      <ng-content select=".properties-title"></ng-content>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-errormessage [error]="error"></app-errormessage>
    <div class="properties" *ngIf="propertiesView.length; else noProperties">
      <mat-table id="properties"
                 class="field-full-width mat-elevation-z0"
                 [dataSource]="propertiesView"
      >

        <!-- Key Column -->
        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef>{{'SEARCH.PROPERTY' | translate}}</th>
          <td mat-cell class="properties-header" *matCellDef="let element">
            <span> {{element.key}} </span>
          </td>
        </ng-container>

        <!-- Auxilliary column -->
        <ng-container matColumnDef="aux">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-chip *ngIf="element.value.length > 1">{{ element.value[1] }}</mat-chip>
            <mat-icon *ngIf="element.key === 'code'" class="grip"
                  [appDraggable]="{data: {code: code, display: display, system: system}, zones: ['conceptZone', 'relationshipZone']}"
            >
              drag_indicator
            </mat-icon>
          </td>
        </ng-container>

        <!-- Value Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>{{'SEARCH.VALUE' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.value[0] }}
          </td>
        </ng-container>

        <tr mat-row *matRowDef="let row; columns: displayedColumns; let idx = index;"
        ></tr>
      </mat-table>
    </div>
  </mat-card-content>
</mat-card>
<ng-template #noProperties>
  <ng-content select=".no-properties"></ng-content>
</ng-template>
