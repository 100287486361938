<div class="full-page-container mat-elevation-z8">
  <form (ngSubmit)="onSubmit()" #myForm="ngForm">
    <mat-card>
      <mat-card-header>
        <mat-card-title>{{ getFormModeTextForTranslation() | translate }}</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content>
        <div *ngIf="loading">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <app-errormessage [error]="error"></app-errormessage>
        <mat-form-field class="field-full-width" hintLabel="{{'FORM.MAX_CHARS' | translate}} {{MAX_TITLE}}">
          <mat-label>{{ 'MAP.TITLE' | translate }}</mat-label>
          <input matInput [(ngModel)]="mappingModel.project.title"
                 id="title" name="title" #title="ngModel"
                 [maxLength]="MAX_TITLE" [pattern]="VALID_STRING_PATTERN"
                 [disabled]="mode === 'FORM.COPY'" required>
          <mat-hint *ngIf="!title.invalid" align="end">{{title.value?.length || 0}}/{{MAX_TITLE}}</mat-hint>
          <mat-error *ngIf="title.invalid">{{ 'MAP.TITLE_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="field-full-width" hintLabel="{{'FORM.MAX_CHARS' | translate}} {{MAX_VERSION}}">
          <mat-label>{{ 'MAP.VERSION' | translate }}</mat-label>
          <input matInput [(ngModel)]="mappingModel.mapVersion"
                 id="mapVersion" name="mapVersion" #version="ngModel"
                 [maxLength]="MAX_VERSION" [pattern]="VALID_STRING_PATTERN"
                 required>
          <mat-hint align="end">{{version.value?.length || 0}}/{{MAX_VERSION}}</mat-hint>
          <mat-error *ngIf="version.invalid">{{ 'MAP.VERSION_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="field-full-width" hintLabel="{{'FORM.MAX_CHARS' | translate}} {{MAX_DESCRIPTION}}">
          <mat-label>{{ 'MAP.DESCRIPTION' | translate }}</mat-label>
          <textarea matNativeControl [(ngModel)]="mappingModel.project.description"
                    id="description" name="description" #description="ngModel"
                    [maxLength]="MAX_DESCRIPTION" [pattern]="VALID_STRING_PATTERN"
                    [disabled]="mode === 'FORM.VIEW' || mode === 'FORM.COPY'"></textarea>
          <mat-hint align="end">{{description.value?.length || 0}}/{{MAX_DESCRIPTION}}</mat-hint>
          <mat-error *ngIf="description.invalid">{{ 'MAP.DESCRIPTION_ERROR' | translate }}</mat-error>
        </mat-form-field>
        <div *ngIf="mode === 'FORM.CREATE' || mode === 'FORM.COPY'">
          <div class="source-field">
            <mat-form-field class="field-withbutton-width">
              <mat-label>{{ 'MAP.SOURCE' | translate }}</mat-label>
              <mat-select [(ngModel)]="mappingModel.source.id"
                          id="sourceId" name="sourceId" #sourceId="ngModel"
                          required>
                <mat-option disabled value=""><i>{{ 'MAP.SOURCE_SELECT' | translate }}</i></mat-option>
                <mat-option *ngFor="let src of sources" [value]="src.id">
                  {{src.name}}: {{src.version}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="sourceId.invalid">{{ 'MAP.SOURCE_REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <button mat-mini-fab color="accent" type="button" title="{{ 'MAP.SOURCE_IMPORT' | translate }}"
                    (click)="addSource($event)">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <mat-form-field class="field-full-width">
            <mat-label for="toSystemSelect">{{ 'MAP.TARGET_SYSTEM' | translate }}</mat-label>
            <mat-select id="toSystemSelect" name="toSystem"
                        [(ngModel)]="mappingModel.toSystem" (selectionChange)="changeSystem($event)" >
              <mat-option *ngFor="let system of config.targetCodeSystems" [value]="system">
                {{system}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="source-field">
            <mat-form-field class="field-withbutton-width multi-field-row-field">
              <mat-label>{{ 'MAP.TARGET_EDITION' | translate }}</mat-label>
              <mat-select id="toEdition" name="toEdition" [(ngModel)]="selectedEdition"
                          (selectionChange)="changeEdition($event.value)" required>
                <mat-option *ngFor="let elem of editionToVersionsMap | keyvalue" [value]="elem.key">
                  {{elem.key}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!selectedEdition">{{ 'MAP.TARGET_EDITION_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field class="field-withbutton-width multi-field-row-field">
              <mat-label>{{ 'MAP.TARGET_VERSION' | translate }}</mat-label>
              <mat-select [(ngModel)]="mappingModel.toVersion"
                          id="toVersion" name="toVersion" #toVersion="ngModel"
                          required>
                <mat-option *ngFor="let vers of editionVersions" [value]="vers.uri">
                  {{vers.version}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="toVersion.invalid">{{ 'MAP.TARGET_VERSION_ERROR' | translate }}</mat-error>
            </mat-form-field>
            <button mat-mini-fab color="accent" type="button" title="{{ 'MAP.REFRESH_TARGET_VERSIONS' | translate }}"
                    (click)="loadReleases(mappingModel.toSystem)">
              <mat-icon>sync</mat-icon>
            </button>
          </div>
          <mat-form-field class="field-full-width" *ngIf="'http://snomed.info/sct' !== mappingModel.toSystem && '' !== mappingModel.toScope">
            <mat-label for="toValueSet">{{ 'MAP.TARGET_SCOPE' | translate }}</mat-label>
            <mat-select id="toValueSet" name="toValueSet"
                        [(ngModel)]="mappingModel.toScope">
              <mat-option [value]="mappingModel.toScope">
                {{mappingModel.toScope}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="field-full-width" hintLabel="{{'FORM.MAX_CHARS' | translate}} {{MAX_TARGETSCOPE}}" *ngIf="'http://snomed.info/sct' === mappingModel.toSystem">
          <mat-label for="toScopeSelect">{{ 'MAP.TARGET_SCOPE_COMMON' | translate }}</mat-label>
            <mat-select id="toScopeSelect" name="toScope"
                        [(ngModel)]="eclScope" (selectionChange)="changeECL($event)">
              <mat-option value="*">{{ 'MAP.ECL_ANY' | translate }}</mat-option>
              <mat-option value="<< 404684003|Clinical finding|">{{ 'MAP.ECL_FINDINGS' | translate }}</mat-option>
              <mat-option value="<< 71388002|Procedure|">{{ 'MAP.ECL_PROCEDURES' | translate }}</mat-option>
              <mat-option value="<< (373873005|Pharmaceutical / biologic product| OR 105590001|Substance|)">{{ 'MAP.ECL_MEDICATIONS' | translate }}</mat-option>
              <mat-option value="<< 410607006|Organism|">{{ 'MAP.ECL_ORGANISMS' | translate }}</mat-option>
              <mat-option>{{ 'MAP.ECL_CUSTOM' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="field-full-width" hintLabel="{{'FORM.MAX_CHARS' | translate}} {{MAX_TARGETSCOPE}}" *ngIf="'http://snomed.info/sct' === mappingModel.toSystem">
            <mat-label for="toScope">{{ 'MAP.TARGET_SCOPE' | translate }}</mat-label>
            <textarea id="toScope" class="ecl" matNativeControl
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="2"
                      matInput [(ngModel)]="eclScope"
                      (change)="changeECL($event)"
                      name="toScope" #toscope="ngModel"
                      [maxLength]="MAX_TARGETSCOPE" [pattern]="VALID_STRING_PATTERN"
                      required></textarea>
            <mat-error
              *ngIf="toscope.invalid || toscope.hasError('maxlength')">{{ 'MAP.TARGET_SCOPE_ERROR' | translate }}</mat-error>
            <a href="https://confluence.ihtsdotools.org/x/lT3oBg" target="_blank">{{ 'MAP.ECL_HELP' | translate }}</a>
            <mat-hint align="end">
              {{toscope.value?.length || 0}}/{{MAX_TARGETSCOPE}}
            </mat-hint>
          </mat-form-field>
          <div *ngIf="mode === 'FORM.CREATE'" class="import-existing-map">
            <button mat-raised-button color="primary"  title="{{ 'FORM.IMPORT_EXISTING_MAP' | translate }}"
                type="button" (click)="onImportMapping($event, myForm)">
                {{ 'FORM.IMPORT_EXISTING_MAP' | translate }}
              </button>
            <span>Selected Mapping File:</span>
            <div>
              {{mappingFile?.source?.source_file?.name}}
              <mat-icon (click)="onClearSelectedMappingFile($event, myForm)"
                title="{{ 'FORM.CLEAR_SELECTED_FILE' | translate }}"
                *ngIf="mappingFile?.source?.source_file?.name">cancel</mat-icon>
            </div>
          </div>
        </div>
        <div *ngIf="mode === 'FORM.VIEW' || mode === 'FORM.EDIT'">
          <br/>
          <div class="info">
            <h3>{{'MAP.CODE_SYSTEMS' | translate}}</h3>
            <table class="table details-table">
              <tr>
                <th><b>{{ 'MAP.SOURCE_LABEL' | translate }}:</b></th>
                <td>{{mappingModel.source.name}}</td>
              </tr>
              <tr>
                <th><b>{{ 'MAP.SOURCE_VERSION_LABEL' | translate }}:</b></th>
                <td>{{mappingModel.source.version}}</td>
              </tr>
              <tr>
                <th><b>{{ 'MAP.TARGET' | translate }}:</b></th>
                <td>
                  <app-target-version [system]="mappingModel.toSystem" [version]="mappingModel.toVersion"></app-target-version>
                </td>
              </tr>
              <tr>
                <th><b>{{ 'MAP.TARGET_SCOPE_LABEL' | translate }}:</b></th>
                <td>{{mappingModel.toScope}}</td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="mode !== 'FORM.COPY'" class="project-roles">
          <h3>{{'PROJECT.PROJECT_ROLES' | translate}}</h3>
          <app-project-roles (errorEvent)="errorUpdate($event)" [readonly]="mode === 'FORM.VIEW'" [project]="mappingModel.project"></app-project-roles>
        </div>
      </mat-card-content>
      <div class="alert-warning warning-message">
        <mat-icon class="mat-icon-warning">info</mat-icon>
        <div [innerHtml]="'IMPORT.IMPORT_LARGE_MAP' | translate" class="infotext"></div>
      </div>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <div class="alert-danger warning-message" *ngIf="warnDelete">
          <mat-icon class="mat-icon-warning">info</mat-icon>
          <div [innerHtml]="'FORM.DELETE_WARN' | translate" class="infotext"></div>
        </div>
        <button *ngIf="mode !== 'FORM.VIEW'" mat-raised-button color="primary" type="submit"
                [disabled]="myForm.form.invalid">{{ 'FORM.SAVE' | translate }}</button>
        <button mat-raised-button color="default" type="cancel"
                (click)="onCancel($event, myForm)">{{ (mode !== 'FORM.VIEW' ? 'FORM.CLOSE' : 'FORM.CANCEL') | translate }}</button>
        <button *ngIf="mode === 'FORM.EDIT'" id="delete" mat-raised-button color="accent" (click)="deleteMap()" type="button">
          {{ (warnDelete ? 'FORM.DELETE_CONFIRM' : 'FORM.DELETE') | translate }}
        </button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
