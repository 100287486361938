<div *ngIf="isAuthenticated; then loaded; else loading;"></div>
<ng-template #loading>
  <ng-container *ngIf="isLoading; else landing">
    <app-loading-spinner></app-loading-spinner>
  </ng-container>
</ng-template>
<ng-template #landing>
  <section>
    <div class="banner__row">
      <div class="banner__container">
        <div>
          <div class="banner__logo">
            <img alt="icon" [src]="bannerLogo">
          </div>
          <h2 class="banner__title"><img alt="icon" [src]="logo" title="{{title}}"></h2>
          <p class="banner__summary" [innerHtml]="summary">A tool for collaborative creation and maintenance of terminology maps.</p>
        </div>
      </div>

      <div class="banner__side">
        <div class="banner__login">
          <button mat-raised-button color="primary" (click)="showLogin()">Login</button>
        </div>
        <div>
          <button mat-raised-button color="primary" (click)="openUserGuide()">{{title}} User Guide</button>
        </div>
        <div *ngIf="hasError" class="login__error">
          <p>{{errorMsg}}</p>
        </div>
        <div class="container__notice" *ngIf="mainPageText">
          <mat-card>
            <mat-card-content>
              <p [innerHtml]="mainPageText"></p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </section>

</ng-template>
<ng-template #loaded>
  <app-home></app-home>
  <app-footer></app-footer>
</ng-template>
