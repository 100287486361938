<div id="target-relationship-container">
  <app-errormessage [error]="error"></app-errormessage>
  <div class="card-relationship">
    <mat-card *ngIf="isNoMap(); else showRelationships">
      <mat-card-content>{{'MAP.NO_MAPPING_AVAILABLE' | translate}}</mat-card-content>
    </mat-card>
    <ng-template #showRelationships>
      <mat-card cdkDropList *ngFor="let relationship of relationships"
                [appDroppable]="{zone: 'relationshipZone'}"
                [dropDisabled]="writeDisableUtils.isEditDisabled(this.task?.type, toMapRowStatus(source?.status))"
                (onDroppableComplete)="onDrop($event, relationship)">
        <mat-card-title>
          <button color="any" mat-icon-button (click)="addFocusTarget(relationship)"
                  [disabled]="disableActions">
            <mat-icon>add</mat-icon>
          </button>
          <span>{{'RELATIONSHIP.' + relationship | translate}}</span>
        </mat-card-title>
        <mat-card-content>
          <mat-selection-list>
            <mat-option *ngFor="let row of filterRows(relationship)" (click)="click(row)">
              <span [appDraggable]="{data:row, zones:['relationshipZone']}"
                    class="row-target">
                <span class="selection-text">{{row.targetDisplay}}</span>
                <span>
                  <button mat-icon-button *ngIf="row.targetOutOfScope" color="accent" #tooltip="matTooltip"
                          matTooltip="{{'TABLE.TARGET_OUT_OF_SCOPE_TOOLTIP' | translate}}"
                          ><mat-icon>warning</mat-icon></button>
                  <button mat-icon-button (click)="updateFlag(row)" [disabled]="disableFlagging"
                          [ngClass]="['flag']"
                          #tooltip="matTooltip" matTooltip="{{'MAP.TARGET_FLAGGED' | translate}}"
                  ><mat-icon *ngIf="row.flagged; else noflag" color="accent">flag</mat-icon>
                  <ng-template #noflag> <mat-icon>outlined_flag</mat-icon></ng-template>
                  </button>
                  <span *ngIf="!disableActions">
                    <button color="accent" mat-mini-fab #tooltip="matTooltip"
                            matTooltip="{{'DETAILS.DELETE' | translate}}"
                            (click)="removeTarget(row)"><mat-icon>remove</mat-icon></button>
                    </span>
                  </span>
              </span>
            </mat-option>
          </mat-selection-list>
        </mat-card-content>
      </mat-card>
    </ng-template>
  </div>
</div>
