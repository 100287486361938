<div>  

    <h2 mat-dialog-title>
      {{ 'NOTESDIALOG.HEADING' | translate }} {{sourceDisplayForHeading}}
    </h2>

    <mat-divider class="divider"></mat-divider>

    <div class="notes-container">
      <table mat-table [dataSource]="notes">

          <ng-container matColumnDef="dateTime">
            <th mat-header-cell *matHeaderCellDef> Date / Time </th>
            <td mat-cell *matCellDef="let element"> {{element.modified | date }} </td>
          </ng-container>
        
          <ng-container matColumnDef="noteBy">
            <th mat-header-cell *matHeaderCellDef> User </th>
            <td mat-cell *matCellDef="let element"> {{element.noteBy.fullName}} </td>
          </ng-container>
        
          <ng-container matColumnDef="noteText">
            <th mat-header-cell *matHeaderCellDef> Note Text </th>
            <td mat-cell *matCellDef="let element"> {{element.noteText}} </td>
          </ng-container>
        
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

    <mat-divider class="divider"></mat-divider>

    <div mat-dialog-actions>
        <button mat-raised-button cdkFocusInitial color="primary" (click)="dialogRef.close()">
          {{'DIALOG.OK' | translate}}
        </button>
    </div>
</div>