<div class="setwidth">
  <div *ngIf="processing">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <h2 mat-dialog-title>
  {{ 'BULKCHANGEDIALOG.SELECTED' | translate }}
  </h2>
  <div *ngIf="isMapView" class="alert-warning box">
    <mat-icon>warning</mat-icon>
    <div>{{'BULKCHANGEDIALOG.OWNER_WARNING' | translate}}</div>
  </div>
  <mat-divider class="divider"></mat-divider>
  <app-errormessage [error]="error"></app-errormessage>
  <div>
    <app-concept-search #searchComponent
      [scope]="data.map?.toScope || data.task?.mapping?.toScope || ''"
      [system]="data.map?.toSystem || data.task?.mapping?.toSystem || ''"
      [version]="data.map?.toVersion || data.task?.mapping?.toVersion || ''"
      *ngIf="((data.task &&  data.task.type !== 'REVIEW') || data.task === null) && !clearTarget && noMapValue == null && changedStatus == null">
      <span class="search-title">{{'BULKCHANGEDIALOG.SELECTED_TARGET' | translate}} {{currentSelection?.display || 'BULKCHANGEDIALOG.NO_SELECTED_TARGET' | translate}}</span>
    </app-concept-search>
    <mat-form-field appearance="fill" class="field-full-width" id="relationships"
      *ngIf="((data.task &&  data.task.type !== 'REVIEW') || data.task === null) && !clearTarget && noMapValue == null && changedStatus == null">
      <mat-label>{{'TABLE.RELATIONSHIP' | translate}}</mat-label>
      <mat-select [(ngModel)]="changedRelationship" ngDefaultControl>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let relationship of relationships" [value]="relationship">
          {{'RELATIONSHIP.' + relationship | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div></div>
    <mat-form-field appearance="fill" class="field-full-width" *ngIf="noMapValue == null && !clearTarget && changedRelationship == null && !hasSearchValue" id="statuses">
      <mat-label>{{'TABLE.STATUS' | translate}}</mat-label>
      <mat-select [(ngModel)]="changedStatus" ngDefaultControl>
        <mat-option>--</mat-option>
        <mat-option *ngFor="let status of statuses; let j = index" [value]="status">
          {{'STATUS.' + status | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="alert-warning" *ngIf="noMapValue != null || clearTarget">{{'BULKCHANGEDIALOG.STATUS_AUTO' | translate}}</div>
  <mat-card *ngIf="((data.task && data.task.type !== 'REVIEW') || data.task === null) && !clearTarget && changedStatus == null && changedRelationship == null && !hasSearchValue">
    <div class="box spaced">
      <span class="box">
        <mat-checkbox color="primary"
          [(ngModel)]="clearNoMap"
          (change)="setClearNoMap($event.checked)" ngDefaultControl></mat-checkbox>
        <span>{{'BULKCHANGEDIALOG.CLEAR_NO_MAP' | translate}}</span>
      </span>
      <span class="box">
        <mat-checkbox color="primary"
          [(ngModel)]="noMap"
          (change)="setNoMap($event.checked)"></mat-checkbox>
        <span>{{'BULKCHANGEDIALOG.SET_NO_MAP' | translate}}</span>
      </span>
    </div>
    <div *ngIf="noMapValue" class="alert-danger">{{'BULKCHANGEDIALOG.NOMAP_WARNING' | translate}}</div>
  </mat-card>
  <mat-card *ngIf="((data.task && data.task.type !== 'REVIEW') || data.task === null) && !(noMapValue != null) && changedStatus == null && changedRelationship == null && !hasSearchValue">
    <div class="box">
      <mat-checkbox color="primary"
      [(ngModel)]="clearTarget"
      (change)="clearTargetClicked($event.checked)" ngDefaultControl></mat-checkbox>
      <span class="auto-margin-top">{{'BULKCHANGEDIALOG.CLEAR_TARGET' | translate}}</span>
    </div>
  </mat-card>
  <mat-divider class="divider"></mat-divider>
  <div class="box spaced">
    <button mat-raised-button color="primary"
      [disabled]="!changedRelationship && !changedStatus && noMapValue == null && !clearTarget"
      (click)="onOk()">{{'DIALOG.OK' | translate}}</button>
    <button mat-raised-button color="any"
      [disabled]="processing"
      (click)="onCancel()">{{'DIALOG.CANCEL' | translate}}</button>
  </div>
</div>
